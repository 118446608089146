/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getUserInfo = /* GraphQL */ `
  query GetUserInfo($id: ID!) {
    getUserInfo(id: $id) {
      id
      name
      surname
      Lat
      Lng
      Address
      balance
      email
      phoneNumber
      createdAt
      updatedAt
      driver
      discountPercentage
      discountAmount
      discountQty
      store
      profileComplete
    }
  }
`;
export const listUserInfos = /* GraphQL */ `
  query ListUserInfos(
    $filter: ModelUserInfoFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserInfos(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        surname
        Lat
        Lng
        Address
        balance
        email
        phoneNumber
        createdAt
        updatedAt
        driver
        discountPercentage
        discountAmount
        discountQty
        store
        profileComplete
      }
      nextToken
    }
  }
`;
export const getShop = /* GraphQL */ `
  query GetShop($id: ID!) {
    getShop(id: $id) {
      id
      name
      Lat
      Lng
      Address
      balance
      email
      phoneNumber
      createdAt
      updatedAt
      logo
      busName
      website
      busReg
      social
      category
      ownerName
      ownerSurname
      ownerEmail
      carryStock
      physStore
    }
  }
`;
export const listShops = /* GraphQL */ `
  query ListShops(
    $filter: ModelShopFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listShops(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        Lat
        Lng
        Address
        balance
        email
        phoneNumber
        createdAt
        updatedAt
        logo
        busName
        website
        busReg
        social
        category
        ownerName
        ownerSurname
        ownerEmail
        carryStock
        physStore
      }
      nextToken
    }
  }
`;
export const getShopRequest = /* GraphQL */ `
  query GetShopRequest($id: ID!) {
    getShopRequest(id: $id) {
      id
      busName
      website
      busReg
      social
      category
      ownerName
      ownerSurname
      ownerEmail
      comments
      status
      carryStock
      physStore
      supplyRet
      unique
      brands
      createdAt
      updatedAt
    }
  }
`;
export const listShopRequests = /* GraphQL */ `
  query ListShopRequests(
    $filter: ModelShopRequestFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listShopRequests(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        busName
        website
        busReg
        social
        category
        ownerName
        ownerSurname
        ownerEmail
        comments
        status
        carryStock
        physStore
        supplyRet
        unique
        brands
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getProducts = /* GraphQL */ `
  query GetProducts($id: ID!) {
    getProducts(id: $id) {
      id
      title
      brand
      price
      newPrice
      image
      images
      quantity
      description
      active
      request
      requestStatus
      category
      isle
      group
      available
      shop
      shopID
      shopLogo
      avgRating
      rating
      noRating
      DaySpecial
      discount
      sizes
      options
      colours
      promoCat
      promoted
      filterCat
      filterCatB
      gender
      ageGroup
      createdAt
      updatedAt
    }
  }
`;
export const listProducts = /* GraphQL */ `
  query ListProducts(
    $filter: ModelProductsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProducts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        brand
        price
        newPrice
        image
        images
        quantity
        description
        active
        request
        requestStatus
        category
        isle
        group
        available
        shop
        shopID
        shopLogo
        avgRating
        rating
        noRating
        DaySpecial
        discount
        sizes
        options
        colours
        promoCat
        promoted
        filterCat
        filterCatB
        gender
        ageGroup
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getProductSizes = /* GraphQL */ `
  query GetProductSizes($id: ID!) {
    getProductSizes(id: $id) {
      id
      name
      ProductID
      createdAt
      updatedAt
    }
  }
`;
export const listProductSizes = /* GraphQL */ `
  query ListProductSizes(
    $filter: ModelProductSizesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProductSizes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        ProductID
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getProductOptions = /* GraphQL */ `
  query GetProductOptions($id: ID!) {
    getProductOptions(id: $id) {
      id
      name
      ProductID
      createdAt
      updatedAt
    }
  }
`;
export const listProductOptions = /* GraphQL */ `
  query ListProductOptions(
    $filter: ModelProductOptionsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProductOptions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        ProductID
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getProductColours = /* GraphQL */ `
  query GetProductColours($id: ID!) {
    getProductColours(id: $id) {
      id
      name
      ProductID
      createdAt
      updatedAt
    }
  }
`;
export const listProductColours = /* GraphQL */ `
  query ListProductColours(
    $filter: ModelProductColoursFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProductColours(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        ProductID
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getProductOD = /* GraphQL */ `
  query GetProductOD($id: ID!) {
    getProductOD(id: $id) {
      id
      title
      description
      image
      images
      options
      categoryA
      categoryB
      avgRating
      ratings
      DaySpecial
      discount
      price
      oldPrice
      createdAt
      updatedAt
    }
  }
`;
export const listProductODS = /* GraphQL */ `
  query ListProductODS(
    $filter: ModelProductODFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProductODS(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        description
        image
        images
        options
        categoryA
        categoryB
        avgRating
        ratings
        DaySpecial
        discount
        price
        oldPrice
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCartProduct = /* GraphQL */ `
  query GetCartProduct($id: ID!) {
    getCartProduct(id: $id) {
      id
      userSub
      quantity
      productID
      title
      price
      image
      brand
      cylinder
      category
      createdAt
      updatedAt
      colour
      size
      see
    }
  }
`;
export const listCartProducts = /* GraphQL */ `
  query ListCartProducts(
    $filter: ModelCartProductFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCartProducts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userSub
        quantity
        productID
        title
        price
        image
        brand
        cylinder
        category
        createdAt
        updatedAt
        colour
        size
        see
      }
      nextToken
    }
  }
`;
export const getOrder = /* GraphQL */ `
  query GetOrder($id: ID!) {
    getOrder(id: $id) {
      id
      userSub
      orderAmount
      paymentStatus
      deliveryStatus
      fullName
      phoneNumber
      address
      status
      statusB
      statusC
      lat
      lng
      country
      city
      amount
      qty
      driverID
      car
      model
      plate
      driverName
      driverSurname
      driverImage
      type
      shop
      shopID
      deliveryType
      deliveryAmount
      paymentMethod
      surname
      createdAt
      updatedAt
    }
  }
`;
export const listOrders = /* GraphQL */ `
  query ListOrders(
    $filter: ModelOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOrders(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userSub
        orderAmount
        paymentStatus
        deliveryStatus
        fullName
        phoneNumber
        address
        status
        statusB
        statusC
        lat
        lng
        country
        city
        amount
        qty
        driverID
        car
        model
        plate
        driverName
        driverSurname
        driverImage
        type
        shop
        shopID
        deliveryType
        deliveryAmount
        paymentMethod
        surname
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getOrderProduct = /* GraphQL */ `
  query GetOrderProduct($id: ID!) {
    getOrderProduct(id: $id) {
      id
      quantity
      price
      image
      title
      brand
      titilet
      cylinder
      productID
      orderID
      colour
      size
      createdAt
      updatedAt
    }
  }
`;
export const listOrderProducts = /* GraphQL */ `
  query ListOrderProducts(
    $filter: ModelOrderProductFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOrderProducts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        quantity
        price
        image
        title
        brand
        titilet
        cylinder
        productID
        orderID
        colour
        size
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getFavourite = /* GraphQL */ `
  query GetFavourite($id: ID!) {
    getFavourite(id: $id) {
      id
      productID
      title
      userSub
      price
      newPrice
      image
      images
      description
      category
      isle
      group
      available
      shop
      shopID
      shopLogo
      avgRating
      rating
      noRating
      DaySpecial
      discount
      sizes
      options
      colours
      createdAt
      updatedAt
    }
  }
`;
export const listFavourites = /* GraphQL */ `
  query ListFavourites(
    $filter: ModelFavouriteFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFavourites(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        productID
        title
        userSub
        price
        newPrice
        image
        images
        description
        category
        isle
        group
        available
        shop
        shopID
        shopLogo
        avgRating
        rating
        noRating
        DaySpecial
        discount
        sizes
        options
        colours
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getDriverList = /* GraphQL */ `
  query GetDriverList($id: ID!) {
    getDriverList(id: $id) {
      id
      name
      surname
      image
      active
      lat
      lng
      userID
      car
      model
      plate
      createdAt
      updatedAt
    }
  }
`;
export const listDriverLists = /* GraphQL */ `
  query ListDriverLists(
    $filter: ModelDriverListFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDriverLists(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        surname
        image
        active
        lat
        lng
        userID
        car
        model
        plate
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCategoryList = /* GraphQL */ `
  query GetCategoryList($id: ID!) {
    getCategoryList(id: $id) {
      id
      title
      level
      parentID
      parentName
      hasChild
      createdAt
      updatedAt
    }
  }
`;
export const listCategoryLists = /* GraphQL */ `
  query ListCategoryLists(
    $filter: ModelCategoryListFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCategoryLists(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        level
        parentID
        parentName
        hasChild
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getMessageList = /* GraphQL */ `
  query GetMessageList($id: ID!) {
    getMessageList(id: $id) {
      id
      title
      message
      backgroundColor
      letter
      author
      read
      date
      time
      userSub
      createdAt
      updatedAt
    }
  }
`;
export const listMessageLists = /* GraphQL */ `
  query ListMessageLists(
    $filter: ModelMessageListFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMessageLists(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        message
        backgroundColor
        letter
        author
        read
        date
        time
        userSub
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getNotificationsList = /* GraphQL */ `
  query GetNotificationsList($id: ID!) {
    getNotificationsList(id: $id) {
      id
      title
      message
      backgroundColor
      letter
      author
      read
      date
      time
      userSub
      createdAt
      updatedAt
    }
  }
`;
export const listNotificationsLists = /* GraphQL */ `
  query ListNotificationsLists(
    $filter: ModelNotificationsListFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listNotificationsLists(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        message
        backgroundColor
        letter
        author
        read
        date
        time
        userSub
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getBannerList = /* GraphQL */ `
  query GetBannerList($id: ID!) {
    getBannerList(id: $id) {
      id
      title
      titletwo
      image
      description
      createdAt
      updatedAt
    }
  }
`;
export const listBannerLists = /* GraphQL */ `
  query ListBannerLists(
    $filter: ModelBannerListFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBannerLists(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        titletwo
        image
        description
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getOrderReport = /* GraphQL */ `
  query GetOrderReport($id: ID!) {
    getOrderReport(id: $id) {
      id
      title
      description
      orderID
      userID
      createdAt
      updatedAt
    }
  }
`;
export const listOrderReports = /* GraphQL */ `
  query ListOrderReports(
    $filter: ModelOrderReportFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOrderReports(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        description
        orderID
        userID
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getReturnProduct = /* GraphQL */ `
  query GetReturnProduct($id: ID!) {
    getReturnProduct(id: $id) {
      id
      title
      description
      productID
      orderID
      userID
      createdAt
      updatedAt
    }
  }
`;
export const listReturnProducts = /* GraphQL */ `
  query ListReturnProducts(
    $filter: ModelReturnProductFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listReturnProducts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        description
        productID
        orderID
        userID
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
