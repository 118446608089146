/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onUpdatedUserDataAdded = /* GraphQL */ `
  subscription OnUpdatedUserDataAdded {
    onUpdatedUserDataAdded {
      id
      name
      surname
      Lat
      Lng
      Address
      balance
      email
      phoneNumber
      createdAt
      updatedAt
      driver
      discountPercentage
      discountAmount
      discountQty
      store
      profileComplete
    }
  }
`;
export const onUpdatedOrder = /* GraphQL */ `
  subscription OnUpdatedOrder {
    onUpdatedOrder {
      id
      userSub
      orderAmount
      paymentStatus
      deliveryStatus
      fullName
      phoneNumber
      address
      status
      statusB
      statusC
      lat
      lng
      country
      city
      amount
      qty
      driverID
      car
      model
      plate
      driverName
      driverSurname
      driverImage
      type
      shop
      shopID
      deliveryType
      deliveryAmount
      paymentMethod
      surname
      createdAt
      updatedAt
    }
  }
`;
export const onAddedOrder = /* GraphQL */ `
  subscription OnAddedOrder {
    onAddedOrder {
      id
      userSub
      orderAmount
      paymentStatus
      deliveryStatus
      fullName
      phoneNumber
      address
      status
      statusB
      statusC
      lat
      lng
      country
      city
      amount
      qty
      driverID
      car
      model
      plate
      driverName
      driverSurname
      driverImage
      type
      shop
      shopID
      deliveryType
      deliveryAmount
      paymentMethod
      surname
      createdAt
      updatedAt
    }
  }
`;
export const onDeletedOrder = /* GraphQL */ `
  subscription OnDeletedOrder {
    onDeletedOrder {
      id
      userSub
      orderAmount
      paymentStatus
      deliveryStatus
      fullName
      phoneNumber
      address
      status
      statusB
      statusC
      lat
      lng
      country
      city
      amount
      qty
      driverID
      car
      model
      plate
      driverName
      driverSurname
      driverImage
      type
      shop
      shopID
      deliveryType
      deliveryAmount
      paymentMethod
      surname
      createdAt
      updatedAt
    }
  }
`;
export const onAddedShopRequest = /* GraphQL */ `
  subscription OnAddedShopRequest {
    onAddedShopRequest {
      id
      busName
      website
      busReg
      social
      category
      ownerName
      ownerSurname
      ownerEmail
      comments
      status
      carryStock
      physStore
      supplyRet
      unique
      brands
      createdAt
      updatedAt
    }
  }
`;
export const onUpdatedShopRequest = /* GraphQL */ `
  subscription OnUpdatedShopRequest {
    onUpdatedShopRequest {
      id
      busName
      website
      busReg
      social
      category
      ownerName
      ownerSurname
      ownerEmail
      comments
      status
      carryStock
      physStore
      supplyRet
      unique
      brands
      createdAt
      updatedAt
    }
  }
`;
export const onDeletedShopRequest = /* GraphQL */ `
  subscription OnDeletedShopRequest {
    onDeletedShopRequest {
      id
      busName
      website
      busReg
      social
      category
      ownerName
      ownerSurname
      ownerEmail
      comments
      status
      carryStock
      physStore
      supplyRet
      unique
      brands
      createdAt
      updatedAt
    }
  }
`;
export const onShopAdded = /* GraphQL */ `
  subscription OnShopAdded {
    onShopAdded {
      id
      name
      Lat
      Lng
      Address
      balance
      email
      phoneNumber
      createdAt
      updatedAt
      logo
      busName
      website
      busReg
      social
      category
      ownerName
      ownerSurname
      ownerEmail
      carryStock
      physStore
    }
  }
`;
export const onShopUpdated = /* GraphQL */ `
  subscription OnShopUpdated {
    onShopUpdated {
      id
      name
      Lat
      Lng
      Address
      balance
      email
      phoneNumber
      createdAt
      updatedAt
      logo
      busName
      website
      busReg
      social
      category
      ownerName
      ownerSurname
      ownerEmail
      carryStock
      physStore
    }
  }
`;
export const onShopDeleted = /* GraphQL */ `
  subscription OnShopDeleted {
    onShopDeleted {
      id
      name
      Lat
      Lng
      Address
      balance
      email
      phoneNumber
      createdAt
      updatedAt
      logo
      busName
      website
      busReg
      social
      category
      ownerName
      ownerSurname
      ownerEmail
      carryStock
      physStore
    }
  }
`;
export const onProductsAdded = /* GraphQL */ `
  subscription OnProductsAdded {
    onProductsAdded {
      id
      title
      brand
      price
      newPrice
      image
      images
      quantity
      description
      active
      request
      requestStatus
      category
      isle
      group
      available
      shop
      shopID
      shopLogo
      avgRating
      rating
      noRating
      DaySpecial
      discount
      sizes
      options
      colours
      promoCat
      promoted
      filterCat
      filterCatB
      gender
      ageGroup
      createdAt
      updatedAt
    }
  }
`;
export const onProductsUpdated = /* GraphQL */ `
  subscription OnProductsUpdated {
    onProductsUpdated {
      id
      title
      brand
      price
      newPrice
      image
      images
      quantity
      description
      active
      request
      requestStatus
      category
      isle
      group
      available
      shop
      shopID
      shopLogo
      avgRating
      rating
      noRating
      DaySpecial
      discount
      sizes
      options
      colours
      promoCat
      promoted
      filterCat
      filterCatB
      gender
      ageGroup
      createdAt
      updatedAt
    }
  }
`;
export const onProductsDeleted = /* GraphQL */ `
  subscription OnProductsDeleted {
    onProductsDeleted {
      id
      title
      brand
      price
      newPrice
      image
      images
      quantity
      description
      active
      request
      requestStatus
      category
      isle
      group
      available
      shop
      shopID
      shopLogo
      avgRating
      rating
      noRating
      DaySpecial
      discount
      sizes
      options
      colours
      promoCat
      promoted
      filterCat
      filterCatB
      gender
      ageGroup
      createdAt
      updatedAt
    }
  }
`;
export const onCatsAdded = /* GraphQL */ `
  subscription OnCatsAdded {
    onCatsAdded {
      id
      title
      level
      parentID
      parentName
      hasChild
      createdAt
      updatedAt
    }
  }
`;
export const onCatsUpdated = /* GraphQL */ `
  subscription OnCatsUpdated {
    onCatsUpdated {
      id
      title
      level
      parentID
      parentName
      hasChild
      createdAt
      updatedAt
    }
  }
`;
export const onCatsDeleted = /* GraphQL */ `
  subscription OnCatsDeleted {
    onCatsDeleted {
      id
      title
      level
      parentID
      parentName
      hasChild
      createdAt
      updatedAt
    }
  }
`;
export const onFavAdded = /* GraphQL */ `
  subscription OnFavAdded {
    onFavAdded {
      id
      productID
      title
      userSub
      price
      newPrice
      image
      images
      description
      category
      isle
      group
      available
      shop
      shopID
      shopLogo
      avgRating
      rating
      noRating
      DaySpecial
      discount
      sizes
      options
      colours
      createdAt
      updatedAt
    }
  }
`;
export const onFavUpdated = /* GraphQL */ `
  subscription OnFavUpdated {
    onFavUpdated {
      id
      productID
      title
      userSub
      price
      newPrice
      image
      images
      description
      category
      isle
      group
      available
      shop
      shopID
      shopLogo
      avgRating
      rating
      noRating
      DaySpecial
      discount
      sizes
      options
      colours
      createdAt
      updatedAt
    }
  }
`;
export const onFavsDeleted = /* GraphQL */ `
  subscription OnFavsDeleted {
    onFavsDeleted {
      id
      productID
      title
      userSub
      price
      newPrice
      image
      images
      description
      category
      isle
      group
      available
      shop
      shopID
      shopLogo
      avgRating
      rating
      noRating
      DaySpecial
      discount
      sizes
      options
      colours
      createdAt
      updatedAt
    }
  }
`;
export const onAddedDriver = /* GraphQL */ `
  subscription OnAddedDriver {
    onAddedDriver {
      id
      name
      surname
      image
      active
      lat
      lng
      userID
      car
      model
      plate
      createdAt
      updatedAt
    }
  }
`;
export const onUpdatedDriver = /* GraphQL */ `
  subscription OnUpdatedDriver {
    onUpdatedDriver {
      id
      name
      surname
      image
      active
      lat
      lng
      userID
      car
      model
      plate
      createdAt
      updatedAt
    }
  }
`;
export const onDeletedDriver = /* GraphQL */ `
  subscription OnDeletedDriver {
    onDeletedDriver {
      id
      name
      surname
      image
      active
      lat
      lng
      userID
      car
      model
      plate
      createdAt
      updatedAt
    }
  }
`;
export const onCartProductUpdatedd = /* GraphQL */ `
  subscription OnCartProductUpdatedd {
    onCartProductUpdatedd {
      id
      userSub
      quantity
      productID
      title
      price
      image
      brand
      cylinder
      category
      createdAt
      updatedAt
      colour
      size
      see
    }
  }
`;
export const onCartProductDeleted = /* GraphQL */ `
  subscription OnCartProductDeleted {
    onCartProductDeleted {
      id
      userSub
      quantity
      productID
      title
      price
      image
      brand
      cylinder
      category
      createdAt
      updatedAt
      colour
      size
      see
    }
  }
`;
export const onCartProductAdded = /* GraphQL */ `
  subscription OnCartProductAdded {
    onCartProductAdded {
      id
      userSub
      quantity
      productID
      title
      price
      image
      brand
      cylinder
      category
      createdAt
      updatedAt
      colour
      size
      see
    }
  }
`;
export const onUpdatedNotifications = /* GraphQL */ `
  subscription OnUpdatedNotifications {
    onUpdatedNotifications {
      id
      title
      message
      backgroundColor
      letter
      author
      read
      date
      time
      userSub
      createdAt
      updatedAt
    }
  }
`;
export const onCreatedNotifications = /* GraphQL */ `
  subscription OnCreatedNotifications {
    onCreatedNotifications {
      id
      title
      message
      backgroundColor
      letter
      author
      read
      date
      time
      userSub
      createdAt
      updatedAt
    }
  }
`;
export const onDeletedNotifications = /* GraphQL */ `
  subscription OnDeletedNotifications {
    onDeletedNotifications {
      id
      title
      message
      backgroundColor
      letter
      author
      read
      date
      time
      userSub
      createdAt
      updatedAt
    }
  }
`;
export const onUpdatedMessages = /* GraphQL */ `
  subscription OnUpdatedMessages {
    onUpdatedMessages {
      id
      title
      message
      backgroundColor
      letter
      author
      read
      date
      time
      userSub
      createdAt
      updatedAt
    }
  }
`;
export const onCreatedMessages = /* GraphQL */ `
  subscription OnCreatedMessages {
    onCreatedMessages {
      id
      title
      message
      backgroundColor
      letter
      author
      read
      date
      time
      userSub
      createdAt
      updatedAt
    }
  }
`;
export const onDeletedMessages = /* GraphQL */ `
  subscription OnDeletedMessages {
    onDeletedMessages {
      id
      title
      message
      backgroundColor
      letter
      author
      read
      date
      time
      userSub
      createdAt
      updatedAt
    }
  }
`;
export const onCreateUserInfo = /* GraphQL */ `
  subscription OnCreateUserInfo {
    onCreateUserInfo {
      id
      name
      surname
      Lat
      Lng
      Address
      balance
      email
      phoneNumber
      createdAt
      updatedAt
      driver
      discountPercentage
      discountAmount
      discountQty
      store
      profileComplete
    }
  }
`;
export const onUpdateUserInfo = /* GraphQL */ `
  subscription OnUpdateUserInfo {
    onUpdateUserInfo {
      id
      name
      surname
      Lat
      Lng
      Address
      balance
      email
      phoneNumber
      createdAt
      updatedAt
      driver
      discountPercentage
      discountAmount
      discountQty
      store
      profileComplete
    }
  }
`;
export const onDeleteUserInfo = /* GraphQL */ `
  subscription OnDeleteUserInfo {
    onDeleteUserInfo {
      id
      name
      surname
      Lat
      Lng
      Address
      balance
      email
      phoneNumber
      createdAt
      updatedAt
      driver
      discountPercentage
      discountAmount
      discountQty
      store
      profileComplete
    }
  }
`;
export const onCreateShop = /* GraphQL */ `
  subscription OnCreateShop {
    onCreateShop {
      id
      name
      Lat
      Lng
      Address
      balance
      email
      phoneNumber
      createdAt
      updatedAt
      logo
      busName
      website
      busReg
      social
      category
      ownerName
      ownerSurname
      ownerEmail
      carryStock
      physStore
    }
  }
`;
export const onUpdateShop = /* GraphQL */ `
  subscription OnUpdateShop {
    onUpdateShop {
      id
      name
      Lat
      Lng
      Address
      balance
      email
      phoneNumber
      createdAt
      updatedAt
      logo
      busName
      website
      busReg
      social
      category
      ownerName
      ownerSurname
      ownerEmail
      carryStock
      physStore
    }
  }
`;
export const onDeleteShop = /* GraphQL */ `
  subscription OnDeleteShop {
    onDeleteShop {
      id
      name
      Lat
      Lng
      Address
      balance
      email
      phoneNumber
      createdAt
      updatedAt
      logo
      busName
      website
      busReg
      social
      category
      ownerName
      ownerSurname
      ownerEmail
      carryStock
      physStore
    }
  }
`;
export const onCreateShopRequest = /* GraphQL */ `
  subscription OnCreateShopRequest {
    onCreateShopRequest {
      id
      busName
      website
      busReg
      social
      category
      ownerName
      ownerSurname
      ownerEmail
      comments
      status
      carryStock
      physStore
      supplyRet
      unique
      brands
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateShopRequest = /* GraphQL */ `
  subscription OnUpdateShopRequest {
    onUpdateShopRequest {
      id
      busName
      website
      busReg
      social
      category
      ownerName
      ownerSurname
      ownerEmail
      comments
      status
      carryStock
      physStore
      supplyRet
      unique
      brands
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteShopRequest = /* GraphQL */ `
  subscription OnDeleteShopRequest {
    onDeleteShopRequest {
      id
      busName
      website
      busReg
      social
      category
      ownerName
      ownerSurname
      ownerEmail
      comments
      status
      carryStock
      physStore
      supplyRet
      unique
      brands
      createdAt
      updatedAt
    }
  }
`;
export const onCreateProducts = /* GraphQL */ `
  subscription OnCreateProducts {
    onCreateProducts {
      id
      title
      brand
      price
      newPrice
      image
      images
      quantity
      description
      active
      request
      requestStatus
      category
      isle
      group
      available
      shop
      shopID
      shopLogo
      avgRating
      rating
      noRating
      DaySpecial
      discount
      sizes
      options
      colours
      promoCat
      promoted
      filterCat
      filterCatB
      gender
      ageGroup
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateProducts = /* GraphQL */ `
  subscription OnUpdateProducts {
    onUpdateProducts {
      id
      title
      brand
      price
      newPrice
      image
      images
      quantity
      description
      active
      request
      requestStatus
      category
      isle
      group
      available
      shop
      shopID
      shopLogo
      avgRating
      rating
      noRating
      DaySpecial
      discount
      sizes
      options
      colours
      promoCat
      promoted
      filterCat
      filterCatB
      gender
      ageGroup
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteProducts = /* GraphQL */ `
  subscription OnDeleteProducts {
    onDeleteProducts {
      id
      title
      brand
      price
      newPrice
      image
      images
      quantity
      description
      active
      request
      requestStatus
      category
      isle
      group
      available
      shop
      shopID
      shopLogo
      avgRating
      rating
      noRating
      DaySpecial
      discount
      sizes
      options
      colours
      promoCat
      promoted
      filterCat
      filterCatB
      gender
      ageGroup
      createdAt
      updatedAt
    }
  }
`;
export const onCreateProductSizes = /* GraphQL */ `
  subscription OnCreateProductSizes {
    onCreateProductSizes {
      id
      name
      ProductID
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateProductSizes = /* GraphQL */ `
  subscription OnUpdateProductSizes {
    onUpdateProductSizes {
      id
      name
      ProductID
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteProductSizes = /* GraphQL */ `
  subscription OnDeleteProductSizes {
    onDeleteProductSizes {
      id
      name
      ProductID
      createdAt
      updatedAt
    }
  }
`;
export const onCreateProductOptions = /* GraphQL */ `
  subscription OnCreateProductOptions {
    onCreateProductOptions {
      id
      name
      ProductID
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateProductOptions = /* GraphQL */ `
  subscription OnUpdateProductOptions {
    onUpdateProductOptions {
      id
      name
      ProductID
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteProductOptions = /* GraphQL */ `
  subscription OnDeleteProductOptions {
    onDeleteProductOptions {
      id
      name
      ProductID
      createdAt
      updatedAt
    }
  }
`;
export const onCreateProductColours = /* GraphQL */ `
  subscription OnCreateProductColours {
    onCreateProductColours {
      id
      name
      ProductID
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateProductColours = /* GraphQL */ `
  subscription OnUpdateProductColours {
    onUpdateProductColours {
      id
      name
      ProductID
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteProductColours = /* GraphQL */ `
  subscription OnDeleteProductColours {
    onDeleteProductColours {
      id
      name
      ProductID
      createdAt
      updatedAt
    }
  }
`;
export const onCreateProductOD = /* GraphQL */ `
  subscription OnCreateProductOD {
    onCreateProductOD {
      id
      title
      description
      image
      images
      options
      categoryA
      categoryB
      avgRating
      ratings
      DaySpecial
      discount
      price
      oldPrice
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateProductOD = /* GraphQL */ `
  subscription OnUpdateProductOD {
    onUpdateProductOD {
      id
      title
      description
      image
      images
      options
      categoryA
      categoryB
      avgRating
      ratings
      DaySpecial
      discount
      price
      oldPrice
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteProductOD = /* GraphQL */ `
  subscription OnDeleteProductOD {
    onDeleteProductOD {
      id
      title
      description
      image
      images
      options
      categoryA
      categoryB
      avgRating
      ratings
      DaySpecial
      discount
      price
      oldPrice
      createdAt
      updatedAt
    }
  }
`;
export const onCreateCartProduct = /* GraphQL */ `
  subscription OnCreateCartProduct {
    onCreateCartProduct {
      id
      userSub
      quantity
      productID
      title
      price
      image
      brand
      cylinder
      category
      createdAt
      updatedAt
      colour
      size
      see
    }
  }
`;
export const onUpdateCartProduct = /* GraphQL */ `
  subscription OnUpdateCartProduct {
    onUpdateCartProduct {
      id
      userSub
      quantity
      productID
      title
      price
      image
      brand
      cylinder
      category
      createdAt
      updatedAt
      colour
      size
      see
    }
  }
`;
export const onDeleteCartProduct = /* GraphQL */ `
  subscription OnDeleteCartProduct {
    onDeleteCartProduct {
      id
      userSub
      quantity
      productID
      title
      price
      image
      brand
      cylinder
      category
      createdAt
      updatedAt
      colour
      size
      see
    }
  }
`;
export const onCreateOrder = /* GraphQL */ `
  subscription OnCreateOrder {
    onCreateOrder {
      id
      userSub
      orderAmount
      paymentStatus
      deliveryStatus
      fullName
      phoneNumber
      address
      status
      statusB
      statusC
      lat
      lng
      country
      city
      amount
      qty
      driverID
      car
      model
      plate
      driverName
      driverSurname
      driverImage
      type
      shop
      shopID
      deliveryType
      deliveryAmount
      paymentMethod
      surname
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateOrder = /* GraphQL */ `
  subscription OnUpdateOrder {
    onUpdateOrder {
      id
      userSub
      orderAmount
      paymentStatus
      deliveryStatus
      fullName
      phoneNumber
      address
      status
      statusB
      statusC
      lat
      lng
      country
      city
      amount
      qty
      driverID
      car
      model
      plate
      driverName
      driverSurname
      driverImage
      type
      shop
      shopID
      deliveryType
      deliveryAmount
      paymentMethod
      surname
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteOrder = /* GraphQL */ `
  subscription OnDeleteOrder {
    onDeleteOrder {
      id
      userSub
      orderAmount
      paymentStatus
      deliveryStatus
      fullName
      phoneNumber
      address
      status
      statusB
      statusC
      lat
      lng
      country
      city
      amount
      qty
      driverID
      car
      model
      plate
      driverName
      driverSurname
      driverImage
      type
      shop
      shopID
      deliveryType
      deliveryAmount
      paymentMethod
      surname
      createdAt
      updatedAt
    }
  }
`;
export const onCreateOrderProduct = /* GraphQL */ `
  subscription OnCreateOrderProduct {
    onCreateOrderProduct {
      id
      quantity
      price
      image
      title
      brand
      titilet
      cylinder
      productID
      orderID
      colour
      size
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateOrderProduct = /* GraphQL */ `
  subscription OnUpdateOrderProduct {
    onUpdateOrderProduct {
      id
      quantity
      price
      image
      title
      brand
      titilet
      cylinder
      productID
      orderID
      colour
      size
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteOrderProduct = /* GraphQL */ `
  subscription OnDeleteOrderProduct {
    onDeleteOrderProduct {
      id
      quantity
      price
      image
      title
      brand
      titilet
      cylinder
      productID
      orderID
      colour
      size
      createdAt
      updatedAt
    }
  }
`;
export const onCreateFavourite = /* GraphQL */ `
  subscription OnCreateFavourite {
    onCreateFavourite {
      id
      productID
      title
      userSub
      price
      newPrice
      image
      images
      description
      category
      isle
      group
      available
      shop
      shopID
      shopLogo
      avgRating
      rating
      noRating
      DaySpecial
      discount
      sizes
      options
      colours
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateFavourite = /* GraphQL */ `
  subscription OnUpdateFavourite {
    onUpdateFavourite {
      id
      productID
      title
      userSub
      price
      newPrice
      image
      images
      description
      category
      isle
      group
      available
      shop
      shopID
      shopLogo
      avgRating
      rating
      noRating
      DaySpecial
      discount
      sizes
      options
      colours
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteFavourite = /* GraphQL */ `
  subscription OnDeleteFavourite {
    onDeleteFavourite {
      id
      productID
      title
      userSub
      price
      newPrice
      image
      images
      description
      category
      isle
      group
      available
      shop
      shopID
      shopLogo
      avgRating
      rating
      noRating
      DaySpecial
      discount
      sizes
      options
      colours
      createdAt
      updatedAt
    }
  }
`;
export const onCreateDriverList = /* GraphQL */ `
  subscription OnCreateDriverList {
    onCreateDriverList {
      id
      name
      surname
      image
      active
      lat
      lng
      userID
      car
      model
      plate
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateDriverList = /* GraphQL */ `
  subscription OnUpdateDriverList {
    onUpdateDriverList {
      id
      name
      surname
      image
      active
      lat
      lng
      userID
      car
      model
      plate
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteDriverList = /* GraphQL */ `
  subscription OnDeleteDriverList {
    onDeleteDriverList {
      id
      name
      surname
      image
      active
      lat
      lng
      userID
      car
      model
      plate
      createdAt
      updatedAt
    }
  }
`;
export const onCreateCategoryList = /* GraphQL */ `
  subscription OnCreateCategoryList {
    onCreateCategoryList {
      id
      title
      level
      parentID
      parentName
      hasChild
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateCategoryList = /* GraphQL */ `
  subscription OnUpdateCategoryList {
    onUpdateCategoryList {
      id
      title
      level
      parentID
      parentName
      hasChild
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteCategoryList = /* GraphQL */ `
  subscription OnDeleteCategoryList {
    onDeleteCategoryList {
      id
      title
      level
      parentID
      parentName
      hasChild
      createdAt
      updatedAt
    }
  }
`;
export const onCreateMessageList = /* GraphQL */ `
  subscription OnCreateMessageList {
    onCreateMessageList {
      id
      title
      message
      backgroundColor
      letter
      author
      read
      date
      time
      userSub
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateMessageList = /* GraphQL */ `
  subscription OnUpdateMessageList {
    onUpdateMessageList {
      id
      title
      message
      backgroundColor
      letter
      author
      read
      date
      time
      userSub
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteMessageList = /* GraphQL */ `
  subscription OnDeleteMessageList {
    onDeleteMessageList {
      id
      title
      message
      backgroundColor
      letter
      author
      read
      date
      time
      userSub
      createdAt
      updatedAt
    }
  }
`;
export const onCreateNotificationsList = /* GraphQL */ `
  subscription OnCreateNotificationsList {
    onCreateNotificationsList {
      id
      title
      message
      backgroundColor
      letter
      author
      read
      date
      time
      userSub
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateNotificationsList = /* GraphQL */ `
  subscription OnUpdateNotificationsList {
    onUpdateNotificationsList {
      id
      title
      message
      backgroundColor
      letter
      author
      read
      date
      time
      userSub
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteNotificationsList = /* GraphQL */ `
  subscription OnDeleteNotificationsList {
    onDeleteNotificationsList {
      id
      title
      message
      backgroundColor
      letter
      author
      read
      date
      time
      userSub
      createdAt
      updatedAt
    }
  }
`;
export const onCreateBannerList = /* GraphQL */ `
  subscription OnCreateBannerList {
    onCreateBannerList {
      id
      title
      titletwo
      image
      description
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateBannerList = /* GraphQL */ `
  subscription OnUpdateBannerList {
    onUpdateBannerList {
      id
      title
      titletwo
      image
      description
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteBannerList = /* GraphQL */ `
  subscription OnDeleteBannerList {
    onDeleteBannerList {
      id
      title
      titletwo
      image
      description
      createdAt
      updatedAt
    }
  }
`;
export const onCreateOrderReport = /* GraphQL */ `
  subscription OnCreateOrderReport {
    onCreateOrderReport {
      id
      title
      description
      orderID
      userID
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateOrderReport = /* GraphQL */ `
  subscription OnUpdateOrderReport {
    onUpdateOrderReport {
      id
      title
      description
      orderID
      userID
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteOrderReport = /* GraphQL */ `
  subscription OnDeleteOrderReport {
    onDeleteOrderReport {
      id
      title
      description
      orderID
      userID
      createdAt
      updatedAt
    }
  }
`;
export const onCreateReturnProduct = /* GraphQL */ `
  subscription OnCreateReturnProduct {
    onCreateReturnProduct {
      id
      title
      description
      productID
      orderID
      userID
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateReturnProduct = /* GraphQL */ `
  subscription OnUpdateReturnProduct {
    onUpdateReturnProduct {
      id
      title
      description
      productID
      orderID
      userID
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteReturnProduct = /* GraphQL */ `
  subscription OnDeleteReturnProduct {
    onDeleteReturnProduct {
      id
      title
      description
      productID
      orderID
      userID
      createdAt
      updatedAt
    }
  }
`;
